.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:0;
    margin-top:20em;
}

.mainBody{
    background-color: white;
    margin: 0;
    padding: 0;
}

.mt20{
    margin-top: 40px;
}

.text{
    text-align: center;
    color: black;
    letter-spacing: 2px;
    font-family: 'Bitter','sans-serif';
    font-weight: bolder;
    font-size: 1.5em;
    text-align: start;
}

.myBtn{

    padding:10px;
    padding-left:20px;
    padding-right: 20px;
    background-color: #ef9d10;
    border: none;
    font-family: 'Bitter','sans-serif';
    text-decoration: none;
    color: #0D0104;

}

.myBtn:hover{
    background-color: #ef9d1065;
}