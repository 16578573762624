/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f179;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #420516;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d03d56;
}

body{
  scroll-behavior: smooth;
}

.navBar {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
}

.navBtn {
  padding: 10px;
  background: transparent;
  font-weight: bold;
  border: none;
  font-size: large;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.navBtn:hover {
  background: #d03d56;
  color: white;
}

.youtubeVideo {
  width: "100%";
  height: "100%";
}

.attachRight{
  position: fixed;
  top: 30%;
  right: 0px;
}

.attachRightBtn{
  background:#d03d56;
  border:none;
  padding:10px;
  cursor: pointer;
}

/*Mobiles*/
@media only screen and (max-width: 600px) {
  
  .pd-top-bottom{
    padding-top: 40%; 
    padding-bottom: 10%
  }

  motleyscape-ar{
    width: 100%; 
    height: 400px; 
  }
  .youtubevideo{
    height: 100px;
  }
  .attachRight{
    display: none;
  }
  .attachRightCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
}

/*Laptops, Desktops etc*/
@media only screen and (min-width: 600px) {

  .pd-top-bottom{
    padding-top: 10%; 
    padding-bottom: 20%
  }

  motleyscape-ar{
    width: 100%; 
    height: 100%; 
  }
  
  .attachRightCard{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 30%;
    right: 0px;
  }

  .attachRight{
    display: block;
  }
}