html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.workBtn {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: #d03d56;
  font-weight: bold;
  border: none;
  font-size: large;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.iconHome {
  width: 20%;
}

.iconNameHome {
  margin-top: 20px;
  width: 60%;
}

.divCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.bgImg {
  overflow: hidden;
  overflow-x: hidden;
  opacity: 0.5;
  width: 100%;
  filter: blur(10px);
}

.HomeTxtHead {
  text-align: center;
  font-family: "Raleway";
  color: white;
  font-size: xx-large;
  font-weight: bold;
}

.backgroundTransparent {
  background: transparent;
}

.dialogBack{
  background: transparent;
  background-color: rgba(0, 0, 0, 0);
}

.readMoreBtn{
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  background: #d03d56;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.wp-block-social-links{
  display: none;
}